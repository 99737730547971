import React from 'react';
import { Stack } from '@mui/material';
import { HeaderText } from '../TicketButton/styles';
import { useAppSelector } from '../../utils/hooks';
import { getEnabledFeatures } from '../../modules/layout/selectors';
import { AppFeature } from '../../utils/device';
import { VioletButton, VioletButtonSmall } from '../styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { EloSecondaryButton } from '../BergButtons';

interface PageHeaderProps {
  title: string;
}
const PageHeader = ({ title }: PageHeaderProps) => {
  const navigate = useNavigate();
  const enabledFeatures = useAppSelector((s) => getEnabledFeatures(s.layout));
  const location = useLocation();
  return (
    <Stack sx={{ justifyContent: 'space-between', alignItems: 'center' }} direction={'row'}>
      <HeaderText sx={{ mb: 1 }}>{title}</HeaderText>
      {location.pathname === '/summary' && enabledFeatures.find((x) => x === AppFeature.ProductSale) ? (
        <EloSecondaryButton onClick={() => navigate('/products')}>Produkty</EloSecondaryButton>
      ) : null}
    </Stack>
  );
};

export default PageHeader;

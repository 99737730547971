import { AppDetails } from './device';

export const AppInfoKey = 'AppDetails';
export const AppDetailsExpireOn = 'AppDetailsExpireOn';
export const Macs = {
  PngsWithPrinter: '1C:EE:C9:14:79:8F',
  TpnSample: '02:00:00:00:00:00',
} as const;

export const SampleTpnAppDetails = {
  id: 50779937,
  name: 'TPN Parking PayBox - test',
  deviceName: '#1',
  appType: 'DROPLABS_TICKET',
  language: 'en',
  languageVersions: ['en', 'fr', 'de', 'pl'],
  client: 'tpn',
  layout: 'mobile',
  enabledFeatures: ['ticketSale', 'ticketSearchByCarPlates', 'ticketScan'],
  primaryMode: 'sales',
  devices: [
    {
      id: 52039571,
      name: 'Drukarka PosNet Temo online',
      type: 'POSNET_FISCAL_PRINTER',
      function: 'FISCAL_PRINTER',
      ip: '192.168.100.121',
      port: '1234',
    },
    {
      id: 52039572,
      name: 'SmartPos Android app',
      type: 'PAYTEL_SMART_POS_PAYMENT_TERMINAL',
      function: 'PAYMENT_TERMINAL',
      ip: 'localhost',
      port: null,
    },
  ],
  hotspotRequired: false,
  fiscalPrinterRequired: true,
  embeddedQrScanner: false,
  embeddedLprScanner: true,
  acceptedPaymentTypes: ['credit_card'],
  disableAppAnimations: false,
} as AppDetails;

export const SamplePNGSAppDetails = {
  id: 49164327,
  name: 'Test app for Droplabs Tickets',
  deviceName: '#1',
  appType: 'DROPLABS_TICKET',
  language: 'en',
  languageVersions: ['en', 'fr', 'de', 'pl'],
  client: 'messe',
  layout: 'desktop',
  enabledFeatures: ['ticketScan', 'ticketSale', 'productSale', 'ticketSearchByCarPlates'],
  primaryMode: 'sales',
  embeddedLprScanner: false,
  embeddedQrScanner: false,
  embeddedQrScannerLocation: 'bottom',
  embeddedQrScannerType: 'barcode',
  embeddedQrScannerIconSize: 'large',
  embeddedQrScannerVerticalLocation: 'bottom',
  devices: [
    {
      id: 46677196,
      name: 'Paytel Terminal',
      type: 'PAYMENT_TERMINAL',
      function: 'PAYMENT_TERMINAL',
      ip: '192.168.100.23',
      port: '5002',
    },
  ],
  hotspotRequired: false,
  fiscalPrinterRequired: true,
  acceptedPaymentTypes: ['credit_card', 'cash'],
  paymentProvider: 'vivaWallet',
  selfCheckout: true,
} as AppDetails;

export const SampleBGPNAppDetails = {
  id: 49164327,
  name: 'Test app for Droplabs Tickets',
  deviceName: '#1',
  appType: 'DROPLABS_TICKET',
  language: 'en',
  languageVersions: ['en', 'fr', 'de', 'pl'],
  client: 'messe',
  layout: 'mobile',
  enabledFeatures: ['ticketScan', 'ticketSale', 'productSale'],
  primaryMode: 'sales',
  embeddedLprScanner: true,
  devices: [
    {
      id: 46677196,
      name: 'Paytel Terminal',
      type: 'PAYMENT_TERMINAL',
      function: 'PAYMENT_TERMINAL',
      ip: '192.168.100.23',
      port: '5002',
    },
  ],
  hotspotRequired: false,
  fiscalPrinterRequired: true,
  acceptedPaymentTypes: ['credit_card', 'cash'],
  paymentProvider: 'vivaWallet',
} as AppDetails;
export const SampleBGPNAppDetails2 = {
  id: 68621444,
  name: 'Berg Regions internal testing - BGPN',
  appType: 'DROPLABS_TICKET',
  language: 'en',
  languageVersions: ['en', 'fr', 'pl', 'de'],
  lastUpdateDate: null,
  deviceName: 'BGPN',
  client: 'bgpn',
  layout: 'desktop',
  enabledFeatures: ['productSale', 'ticketSale', 'ticketScan'],
  acceptedPaymentTypes: ['cash', 'credit_card'],
  primaryMode: 'sales',
  paymentProvider: 'payTel',
  embeddedQrScannerLocation: 'none',
  embeddedQrScannerType: 'none',
  embeddedQrScannerVerticalLocation: 'middle',
  embeddedQrScannerIconSize: 'medium',
  devices: [
    {
      id: 68621447,
      name: 'PosNet TEMO Online',
      type: 'POSNET_FISCAL_PRINTER',
      function: 'FISCAL_PRINTER',
      ip: '192.168.43.232',
      port: '6666',
      additionalSettings: {},
    },
    {
      id: 68621448,
      name: 'PayTel LightPos',
      type: 'PAYTEL_SMART_POS_PAYMENT_TERMINAL',
      function: 'PAYMENT_TERMINAL',
      ip: 'localhost',
      port: null,
      additionalSettings: {
        payTelCallbackUrl: 'https://www.bergregions.pl/receipt',
        payTelSoftPosUrl: 'www.paytel.pl',
        payTelKey: 'G10NGOX43TNOP2EVH05771GK271QFHK1',
        payTelPassword: '5469Pv',
        payTelServiceUrl: 'https://uat-softpos.sibs.ro/Mam/deeplink/api/',
        payTelLogin: 'info@berg.pl',
        payTelUserHash: '48725277709',
      },
    },
  ],
  disableAppAnimations: false,
  hotspotRequired: false,
  fiscalPrinterRequired: true,
  operatorLoginEnabled: false,
  selfCheckout: false,
  embeddedQrScanner: true,
  embeddedLprScanner: false,
} as AppDetails;

export const SampleAmplusAppDetails = {
  id: 68621956,
  name: 'Berg Regions internal testing - Amplus',
  appType: 'DROPLABS_TICKET',
  language: 'en',
  languageVersions: ['en', 'fr', 'de', 'pl'],
  lastUpdateDate: null,
  deviceName: 'AMPL',
  client: 'amplus',
  layout: 'desktop',
  enabledFeatures: ['ticketSale', 'ticketScan'],
  acceptedPaymentTypes: ['credit_card'],
  primaryMode: 'ticketScan',
  paymentProvider: 'payTel',
  embeddedQrScannerLocation: 'bottom',
  embeddedQrScannerType: 'barcode',
  embeddedQrScannerVerticalLocation: 'bottom',
  embeddedQrScannerIconSize: 'large',
  devices: [
    {
      id: 68621959,
      name: 'Posnet THERMAL XL2',
      type: 'POSNET_FISCAL_PRINTER',
      function: 'FISCAL_PRINTER',
      ip: '192.168.0.1',
      port: '6666',
      additionalSettings: {},
    },
    {
      id: 68621960,
      name: 'PayTel Terminal',
      type: 'PAYMENT_TERMINAL',
      function: 'PAYMENT_TERMINAL',
      ip: '192.168.0.2',
      port: '5002',
      additionalSettings: {},
    },
  ],
  disableAppAnimations: false,
  hotspotRequired: false,
  fiscalPrinterRequired: true,
  operatorLoginEnabled: false,
  selfCheckout: true,
  embeddedQrScanner: true,
  embeddedLprScanner: false,
} as AppDetails;

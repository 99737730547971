import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { GreenButton, GreyButton, RedButton, ScanNextTicketButton } from '../../components/styled-components';
import { TicketDetails, TicketOption, TicketWarning } from '../../modules/tickets/models';
import { extendTicket, getTicketDetails } from '../../modules/tickets/operations';
import ExtendingTicketConfirmation from './ExtendingTicketConfirmation';
import InformationDialog from './InformationDialog';
import { getFormattedDate, HeaderColumnText, HeaderText, ValueColumnText } from './ValidTicket';
import Grid from '@mui/material/Unstable_Grid2';
import { useAppDispatch } from '../../utils/hooks';
import { addOrUpdateBasketTicket, clearBasket } from '../../modules/basket/slice';
import { BasketTicket } from '../../modules/basket/models';
import { Ticket, TicketType } from '../../modules/tickets/models';
import { useProcessPayment, useSinglePaymentType } from '../../modules/payment/hooks';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getReturnUrl } from '../../utils/navigationHelper';
import InfoIcon from '@mui/icons-material/Info';

const getWarningNames = (ticketWarnings: TicketWarning[]): string[] => {
  const warnings = [] as string[];

  if (ticketWarnings.find((x) => x === TicketWarning.TicketOverdue)) {
    warnings.push(i18next.t('common:ticketStatus.ticketOverdueMessage'));
  }
  if (ticketWarnings.find((x) => x === TicketWarning.TicketFutureDate)) {
    warnings.push(i18next.t('common:ticketStatus.ticketFutureDateMessage'));
  }
  if (ticketWarnings.find((x) => x === TicketWarning.TicketNotPaid)) {
    warnings.push(i18next.t('common:ticketStatus.ticketNotPaidMessage'));
  }
  if (ticketWarnings.find((x) => x === TicketWarning.TicketAlreadyUsed)) {
    warnings.push(i18next.t('common:ticketStatus.ticketAlreadyUsedMessage'));
  }
  return warnings;
};

const getImageSrc = (ticketWarnings: TicketWarning[] | undefined) => {
  if (!ticketWarnings === undefined) {
    return '/images/invalid.png';
  }
  if (ticketWarnings?.find((x) => x === TicketWarning.TicketOverdue)) {
    return '/images/calendar.png';
  }
  if (ticketWarnings?.find((x) => x === TicketWarning.TicketFutureDate)) {
    return '/images/invalid.png';
  }
  if (ticketWarnings?.find((x) => x === TicketWarning.TicketNotPaid)) {
    return '/images/invalid.png';
  }
  if (ticketWarnings?.find((x) => x === TicketWarning.TicketAlreadyUsed)) {
    return '/images/invalid.png';
  }

  return '/images/invalid.png';
};

const ValidTicket = () => {
  const { t } = useTranslation('common');
  const { id } = useParams();
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();
  const [singlePaymentType] = useSinglePaymentType();
  const [processPayment] = useProcessPayment(singlePaymentType);
  const dispatch = useAppDispatch();

  const [ticket, setTicket] = useState<TicketDetails | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openInfoDialog, setOpenInfoDialog] = useState<boolean>(false);
  const [infoDialogMessage, setInfoDialogMessage] = useState<string>('');
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const GetTicket = async () => {
      if (id) {
        const td = await getTicketDetails(id);
        setTicket(td);
        dispatch(clearBasket());
        if (td) {
          const basketTicket = {
            amount: td.price,
            quantity: 1,
            question: {
              answer: ticket?.question.answer,
              questionId: ticket?.question.questionAnswerId,
            },
            ticket: {
              variantId: td.variantId,
              type: TicketType.PARKING,
            } as Ticket,
            id: td.ticketId,
          } as BasketTicket;
          dispatch(addOrUpdateBasketTicket(basketTicket));
        }
      }
    };
    GetTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExtendTicket = () => {
    setOpenDialog(true);
  };

  const handleConfirmExtendTicket = async () => {
    if (ticket) {
      const result = await extendTicket(ticket.uuid);
      setOpenDialog(false);
      if (result) {
        setOpenInfoDialog(false);
        setInfoDialogMessage(t('ticketStatus.extendedTicketConfirmationMessage'));
        setOpenInfoDialog(true);
        setTimeout(() => {
          dispatch(clearBasket());
          navigate('/');
        }, 2000);
      } else {
        setInfoDialogMessage(t('ticketStatus.extendingTicketIssueMessage'));
        setOpenInfoDialog(true);
      }
    }
  };

  const handlePaymentClick = async () => {
    if (ticket) {
      if (singlePaymentType) {
        await processPayment(ticket.uuid);
      } else {
        navigate(`/summary?ticketId=${ticket.uuid}&r=/`);
      }
    }
  };

  return (
    <>
      <Grid container sx={{ pt: 2 }}>
        <Grid lgOffset={3} lg={6} xs={12} sx={{ backgroundColor: 'white', px: isMdScreen ? 2 : 6, pb: 2 }}>
          <Stack
            direction="column"
            sx={{ width: '100%', alignItems: 'center', mt: isMdScreen ? 0 : 4 }}
            spacing={{ xs: 3, md: 3, lg: 4 }}
          >
            {ticket ? (
              getWarningNames(ticket.ticketWarnings).map((description, i) => (
                <HeaderText key={i}>{description}</HeaderText>
              ))
            ) : (
              <HeaderText>
                <>{t('ticketStatus.wrongTicketHeader')}</>
              </HeaderText>
            )}
            <Box component="img" src={getImageSrc(ticket?.ticketWarnings)} sx={{ height: '20vh' }}></Box>
            {ticket?.userMessage != null && ticket.userMessage.length > 0 ? (
              <Stack direction={'row'} spacing={2}>
                <InfoIcon />
                <HeaderColumnText>
                  <>{ticket.userMessage}</>
                </HeaderColumnText>{' '}
              </Stack>
            ) : null}
            <Box sx={{ alignItems: 'left' }}>
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('ticketStatus.ticketIdHeader')}</>:
                </HeaderColumnText>
                <ValueColumnText>{ticket?.ticketId}</ValueColumnText>
              </Stack>
              {ticket?.question != null && (
                <Stack direction="row" spacing={2}>
                  <HeaderColumnText>{ticket?.question?.question}:</HeaderColumnText>
                  <ValueColumnText>{ticket?.question?.answer}</ValueColumnText>
                </Stack>
              )}
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('ticketStatus.ticketTypeHeader')}</>:
                </HeaderColumnText>
                <ValueColumnText>{ticket?.variantName}</ValueColumnText>
              </Stack>
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('ticketStatus.validSinceHeader')}</>:
                </HeaderColumnText>
                <ValueColumnText>{getFormattedDate(ticket?.validSince)}</ValueColumnText>
              </Stack>
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('ticketStatus.validUntilHeader')}</>:
                </HeaderColumnText>
                <ValueColumnText>{getFormattedDate(ticket?.validUntil)}</ValueColumnText>
              </Stack>
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('ticketStatus.ticketPriceHeder')}</>:
                </HeaderColumnText>
                <ValueColumnText>
                  {ticket?.price} {ticket?.currency}
                </ValueColumnText>
              </Stack>
            </Box>
            <Stack direction="row" spacing={1}>
              <RedButton
                onClick={() => {
                  dispatch(clearBasket());
                  const returnUrl = getReturnUrl(searchParams, '/');
                  navigate(returnUrl);
                }}
              >
                <>{t('buttons.cancel')}</>
              </RedButton>
              {ticket != null && ticket.ticketOptions?.findIndex((x) => x === TicketOption.TicketReschedule) >= 0 && (
                <GreyButton onClick={handleExtendTicket}>
                  <>{t('ticketStatus.extendTicket')}</>
                </GreyButton>
              )}
              {ticket != null && ticket.ticketOptions.findIndex((x) => x === TicketOption.TicketPayment) >= 0 && (
                <GreenButton onClick={handlePaymentClick}>
                  <>{t('ticketStatus.payForTicket')}</>
                </GreenButton>
              )}
            </Stack>
            <ScanNextTicketButton
              onClick={() => {
                dispatch(clearBasket());
                const plateSearch = searchParams.get('plateSearch');
                if (plateSearch) {
                  navigate('/plate_search');
                } else {
                  navigate('/scan');
                }
              }}
            >
              <>{t('ticketStatus.scanNext')}</>
            </ScanNextTicketButton>
          </Stack>
        </Grid>
      </Grid>
      <ExtendingTicketConfirmation
        open={openDialog}
        ticket={ticket}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={handleConfirmExtendTicket}
      ></ExtendingTicketConfirmation>
      <InformationDialog
        handleClose={() => setOpenInfoDialog(false)}
        message={infoDialogMessage}
        open={openInfoDialog}
      ></InformationDialog>
    </>
  );
};

export default ValidTicket;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAppDetails } from '../../utils/terminal';
import axiosInstance from '../../utils/axios';
import actionTypes from './actionTypes';
import { ParkingTicketEntry } from './models';

export const fetchParkingTickets = createAsyncThunk(
  actionTypes.FETCH_PARKING_TICKETS,
  async (plateNo: string): Promise<ParkingTicketEntry[]> => {
    try {
      const appDetails = await getAppDetails();
      const response = await axiosInstance.get<ParkingTicketEntry[]>(
        `/v1/paybox/${appDetails.id}/tickets/search?text=${plateNo}&page=1&pageSize=50`
      );
      return response.data;
    } catch (e) {
      // TODO: display error message
      console.error(JSON.stringify(e));
      return [] as ParkingTicketEntry[];
    }
  }
);

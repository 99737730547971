import { Box, Snackbar, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getBasketTicketSummary } from '../modules/basket/selectors';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import { useEffect, useState } from 'react';
import { fetchTickets } from '../modules/tickets/operations';
import MainTickets from './tickets/MainTickets';
import TodayStatsBar from './tickets/TodayStatsBar';
import { getTickets } from '../modules/tickets/selectors';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import PageHeader from '../components/PageHeader/PageHeader';
import FreeTicketsSection from './tickets/FreeTicketSection';
import BottomNavigationWrapper from './tickets/BottomNavigationWrapper';

const NoTicketsText = styled(Typography)({
  fontWeight: 700,
  fontSize: '2rem',
});

const Tickets = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const isMDScreen = useMediaQuery(theme.breakpoints.up('md'));
  const tickets = useAppSelector((s) => getTickets(s.tickets));

  useEffect(() => {
    dispatch(fetchTickets());
    const interval = setInterval(() => {
      console.log('Tickets - scheduled - fetch tickets');
      dispatch(fetchTickets());
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ py: 2 }}>
      {!isMDScreen && <TodayStatsBar></TodayStatsBar>}
      {tickets.length === 0 && (
        <Box
          sx={{
            p: 2,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            my: 4,
          }}
        >
          <NoTicketsText align="center">{t('common:tickets.noTickets').toString()}</NoTicketsText>
        </Box>
      )}
      <PageHeader title={t('common:tickets.mainTicketWindowHeader')}></PageHeader>
      <MainTickets></MainTickets>
      <FreeTicketsSection></FreeTicketsSection>
      {tickets.length > 0 && <BottomNavigationWrapper returnUrl="/" />}
    </Box>
  );
};

export default Tickets;

import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Layout from './layout/layout/Layout';
import Tickets from './pages/Tickets';
import PaymentWizard from './pages/PaymentWizard';
import Cash from './pages/paymentWizzard/Cash';
import Terminal from './pages/paymentWizzard/Terminal';
import Confirmation from './pages/paymentWizzard/Terminal/Confirmation';
import QRCode from './pages/QRCode';
import Summary from './pages/Summary';
import Home from './pages/Home';
import ParkingPlate from './pages/ParkingPlate';
import ParkingSummary from './pages/ParkingSummary';
import BarcodeScanner from './pages/BarcodeScanner';
import ValidTicket from './pages/ticket-status/ValidTicket';
import InValidTicket from './pages/ticket-status/InvalidTicket';
import CompanyDetails from './pages/summary/CompanyDetails';
import PlateSearcher from './pages/PlateSearcher';
import SearchResult from './pages/plate-searcher/SearchResult';
import FreeTickets from './pages/tickets/FreeTickets';
import './i18n/i18n';
import AuthProvider from './components/AuthProvider/AuthProvider';
import Products from './pages/products/Products';
import PageTransitionWrapper from './components/PageTransitionWrapper/PageTransitionWrapper';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './utils/hooks';
import { getAppDetails, getPrimaryMode } from './modules/layout/selectors';
import { PrimaryMode } from './utils/device';
import { clearBasket } from './modules/basket/slice';
import i18n from './i18n/i18n';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const primaryMode = useAppSelector((x) => getPrimaryMode(x.layout));
  const appDetails = useAppSelector((l) => getAppDetails(l.layout));
  const dispatch = useAppDispatch();

  let timeout: ReturnType<typeof setTimeout>;
  let newLocation;
  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      console.log(`Reset to primary mode: ${primaryMode} on path: ${newLocation}`);
      if (newLocation == '/payment_wizard' || newLocation == '/payment_wizard/terminal') {
        return;
      }
      if (primaryMode == PrimaryMode.Sales && newLocation != '/tickets') {
        i18n.changeLanguage(appDetails.language);
        navigate('/tickets');
        dispatch(clearBasket());
      }
      if (primaryMode == PrimaryMode.TicketScan && newLocation != '/scan') {
        i18n.changeLanguage(appDetails.language);
        navigate('/scan');
        dispatch(clearBasket());
      }
    }, 1000 * 3 * 60);
  };

  const onMouseMove = () => {
    restartAutoReset();
  };

  useEffect(() => {
    console.log('primaryMode', primaryMode);
    if (primaryMode !== PrimaryMode.None && location?.pathname) {
      newLocation = location.pathname;
      restartAutoReset();
      window.addEventListener('mousemove', onMouseMove);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener('mousemove', onMouseMove);
      }
    };
  }, [location.pathname, primaryMode]);

  return (
    <Layout>
      <AnimatePresence mode="wait">
        <AuthProvider>
          <Routes location={location} key={location.key}>
            <Route element={<PageTransitionWrapper></PageTransitionWrapper>}>
              <Route path="/" element={<Home></Home>}></Route>
              <Route path="tickets" element={<Tickets></Tickets>}></Route>
              <Route path="tickets/free" element={<FreeTickets></FreeTickets>}></Route>
              <Route path="vehicle" element={<ParkingPlate></ParkingPlate>}></Route>
              <Route path="plate_search" element={<PlateSearcher></PlateSearcher>}></Route>
              <Route path="plate_search/result" element={<SearchResult></SearchResult>}></Route>
              <Route path="vehicle/ticket/:variantId/:plate" element={<ParkingSummary></ParkingSummary>}></Route>
              <Route path="summary" element={<Summary />}></Route>
              <Route path="summary/company-details" element={<CompanyDetails />}></Route>
              <Route path="payment_wizard" element={<PaymentWizard />}></Route>
              <Route path="payment_wizard/terminal" element={<Terminal />}></Route>
              <Route path="payment_wizard/cash" element={<Cash />}></Route>
              <Route path="payment_wizard/terminal/confirmation" element={<Confirmation />}></Route>
              <Route path="qrcodes" element={<QRCode />}></Route>
              <Route path="scan" element={<BarcodeScanner />}></Route>
              <Route path="/ticket/valid/:id" element={<ValidTicket />}></Route>
              <Route path="/ticket/invalid/:id" element={<InValidTicket />}></Route>
              <Route path="/products" element={<Products />}></Route>
            </Route>
          </Routes>
        </AuthProvider>
      </AnimatePresence>
    </Layout>
  );
};

export default App;

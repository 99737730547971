export enum Client {
  TPN = 'tpn',
  PNGS = 'pngs',
  Messe = 'messe',
  Bgpn = 'bgpn',
  Amplus = 'amplus',
  Meander = 'meander',
  Hawran = 'hawran',
  None = 'none',
}
